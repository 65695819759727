import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Portal from '../portal/Portal';
import styles from './Modal.module.scss';
import modalTransition from './ModalTransition.module.scss';

interface Props {
  /** Used to differentiate multiple modals */
  modalId: string;
  /** Modal open state */
  isOpen: boolean;
  /** onClick is invoked on click outside the modal and can be used to for example close the modal  */
  onClick?(): void;
  /** Translates modal x, y percent */
  translate?: [number, number];
  /** Sets transparent overlay background element. Default background is invisible */
  hasBackground?: boolean;
  /** Target ID of DOM element that the modal should anchor to */
  targetId?: string;
  /** Target anchor position */
  targetAnchor?: [
    'top' | 'middle' | 'bottom' | 'left' | 'center' | 'right',
    'top' | 'middle' | 'bottom' | 'left' | 'center' | 'right'
  ];
  /** Modal anchor position */
  modalAnchor?: [
    'top' | 'middle' | 'bottom' | 'left' | 'center' | 'right',
    'top' | 'middle' | 'bottom' | 'left' | 'center' | 'right'
  ];
  minWidth?: number | string;
  width?: number | string;
  maxWidth?: number | string;
  /** Can be used to override innerWidth and innerHeight of the window */
  windowDimension?: [number, number];
  className?: string;
  style?: React.CSSProperties;
}

interface State {
  translate?: [number | null, number | null] | [];
  style?: React.CSSProperties;
  windowDimension?: [number, number] | [];
  scrollY?: number;
}

type ComputeRect = {
  [key: string]: number;
} | null;

/*
 * TODO: enable/disable on click outside modal to allow it to function as a nonmodal
 * TODO: Make some form of alias for translating a fixed position.
 * So instead of translate={[X,Y]} then maybe position={['center', 'middle']}
 */

/**
 * The modal component can be used for dialogs and other content that needs focus
 * and needs to live outside of the root.
 */
const Modal: React.FC<Props> = ({
  isOpen,
  minWidth,
  width,
  maxWidth,
  translate,
  targetId,
  targetAnchor,
  modalId,
  modalAnchor,
  onClick,
  className,
  children,
  hasBackground,
  style,
}) => {
  const [state, setState] = React.useState<State>({
    translate: [],
    style: {},
    windowDimension: [],
    scrollY: 0,
  });

  const modalRef = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    const updateWindowDimensions = (): void => {
      let ticking = false;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          // Resize events can fire at a high rate so we throttle the event
          setState((prevState) => ({
            ...prevState,
            windowDimension: [window.innerWidth, window.innerHeight],
          }));

          ticking = false;
        });

        ticking = true;
      }
    };

    const updateScrollPosition = (): void => {
      let ticking = false;

      if (!ticking) {
        // Scroll events can fire at a high rate so we throttle the event
        window.requestAnimationFrame(() => {
          setState((prevState) => ({ ...prevState, scrollY: window.scrollY }));
          ticking = false;
        });

        ticking = true;
      }
    };

    // Listen for resize and update window dimensions if any changes
    window.addEventListener('resize', updateWindowDimensions);

    // Listen for scroll
    window.addEventListener('scroll', updateScrollPosition);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
      window.removeEventListener('scroll', updateScrollPosition);
    };
  });

  React.useEffect(() => {
    const translateStyles = (): React.CSSProperties => {
      if (translate && !targetId) {
        return {
          left: `${translate[0]}%`,
          top: `${translate[1]}%`,
          transform: `translate(-${translate[0]}%, -${translate[1]}%)`,
        };
      } else {
        return {};
      }
    };

    // Set base style
    setState((prevState) => ({
      ...prevState,
      style: {
        ...prevState.style,
        minWidth,
        width,
        maxWidth,
        ...style,
        // Set and merge hard coded translate values with base style
        ...translateStyles(),
      },
    }));
  }, [maxWidth, minWidth, style, targetId, translate, width]);

  React.useEffect(() => {
    const getBoundingClientRect = (
      selector: string
    ): ClientRect | DOMRect | null => {
      const element = selector && document.querySelector(`#${selector}`);

      return element ? element.getBoundingClientRect() : null;
    };

    const modalCoordinate = (
      target: number | null,
      selector: string,
      type: string
    ): number | null => {
      const rect = getBoundingClientRect(selector);

      const computedRect: ComputeRect =
        rect && target
          ? {
              top: target,
              middle: target - rect.height / 2,
              bottom: target - rect.height,
              left: target,
              center: target - rect.width / 2,
              right: target - rect.width,
            }
          : null;

      return computedRect ? computedRect[type] : null;
    };

    const computeTargetAnchor = (
      selector: string,
      type: string
    ): number | null => {
      const rect = getBoundingClientRect(selector);

      const computedRect: ComputeRect = rect
        ? {
            top: rect.top,
            middle: rect.top + rect.height / 2,
            bottom: rect.top + rect.height,
            left: rect.left,
            center: rect.left + rect.width / 2,
            right: rect.left + rect.width,
          }
        : null;

      return computedRect ? computedRect[type] : null;
    };

    const getCoordinate = (axis: number): number | null => {
      return targetId && targetAnchor && modalAnchor
        ? modalCoordinate(
            computeTargetAnchor(targetId, targetAnchor[axis]),
            modalId,
            modalAnchor[axis]
          )
        : null;
    };

    if (isOpen && modalRef.current) modalRef.current.focus();

    // Update if modal is targeting a dom element and open or window dimension has changed
    if (isOpen && targetId && state.windowDimension && state.scrollY) {
      setState((prevState) => ({
        ...prevState,
        translate: [getCoordinate(0), getCoordinate(1)],
        style: {
          ...prevState.style,
          left: 0,
          top: 0,
          transform: `translate(${getCoordinate(0)}px, ${getCoordinate(1)}px)`,
        },
      }));
    }
  }, [isOpen, state, targetId, modalRef, targetAnchor, modalAnchor, modalId]);

  /**
   * Handle key board navigation
   */
  const handleKeyDown = (event: React.KeyboardEvent): void => {
    // Close modal on Esc
    if (event.key === 'Escape') {
      event.preventDefault();
      onClick && onClick();
    }
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={200}
      unmountOnExit
      classNames={modalTransition}>
      <Portal position="fixed">
        <div className={styles.modalContainer}>
          <div
            ref={modalRef}
            id={modalId}
            className={`${styles.modal} ${styles.modalShadow} ${className}`}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            style={state.style}>
            {children}
          </div>
          <div
            className={`${styles.overlay} ${
              hasBackground ? styles.transparentBackground : ''
            }`}
            onClick={onClick}
          />
        </div>
      </Portal>
    </CSSTransition>
  );
};

export default Modal;
