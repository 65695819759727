import React from 'react';
import { hasKey } from '../../types/commonTypes';
import styles from './Typography.module.scss';

const variantMapping: { [key: string]: string } = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'p',
  body2: 'p',
  caption: 'p',
  small: 'p',
  tiny: 'p',
  srOnly: 'p',
};

interface Props {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'small'
    | 'tiny'
    | 'srOnly';
  display?: 'block' | 'inline';
  component?: string;
  noMargin?: boolean;
  isUnselectable?: boolean;
  noWrap?: boolean;
  noLineHeight?: boolean;
  color?: 'dark' | 'light' | 'primary' | 'error';
  title?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial';
  className?: string;
  style?: React.CSSProperties;
}

export const Typography: React.FC<Props> = ({
  children,
  variant = 'body1',
  className = '',
  display = 'block',
  component = null,
  noMargin = false,
  color = 'secondary',
  isUnselectable = false,
  noWrap = false,
  noLineHeight = false,
  title = '',
  textAlign = 'initial',
  style,
}) => {
  const classes = `${
    hasKey(styles, variant) ? styles[variant] : styles.body1
  } ${hasKey(styles, color) ? styles[color] : styles.dark} ${
    hasKey(styles, display) ? styles[display] : styles.block
  } ${hasKey(styles, textAlign) ? styles[textAlign] : styles.initial} ${
    noMargin ? styles.noMargin : ''
  } ${isUnselectable ? styles.isUnselectable : ''} ${
    noWrap ? styles.noWrap : ''
  } ${noLineHeight ? styles.noLineHeight : ''} ${className}`;

  return React.createElement(
    component ?? variantMapping[variant],
    {
      className: classes.trim(),
      title: title,
      style: { ...style },
    },
    children
  );
};
