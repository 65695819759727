import React from 'react';
import { hasKey } from '../../../types/commonTypes';
import { Size } from '../../../types/interfaces';
import styles from './ListItem.module.scss';

interface Props extends Size {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  style?: React.CSSProperties;
  hasFullWidthBorder?: boolean;
}

const ListItem: React.FC<Props> = ({
  size = 'medium',
  onClick,
  className = '',
  style,
  children,
  hasFullWidthBorder = true,
}): JSX.Element => {
  return (
    <div
      className={`${styles.listItem} ${
        hasKey(styles, size) ? styles[size] : ''
      } ${hasFullWidthBorder ? styles.hasFullWidthBorder : ''} ${className}`}
      style={{ ...style }}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default ListItem;
