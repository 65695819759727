import React from 'react';
import { hasKey } from '../../types/commonTypes';
import styles from './Button.module.scss';

interface Props<T> {
  id?: string;
  style?: React.CSSProperties;
  color?: 'primary' | 'error' | 'warning' | 'default';
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
  className?: string;
  /** Use the aria-label attribute to label the current element where a text label is not visible on the screen. */
  ariaLabel?: string;
  onClick?: React.MouseEventHandler<T>;
  onMouseOver?: React.MouseEventHandler<T>;
  icon?: React.ReactNode;
  iconEnd?: boolean;
  variant?: 'text' | 'outline' | 'solid';
  shape?: 'square' | 'round';
  hasShadow?: boolean;
  children?: React.ReactNode;
  component?: string;
  ref?: React.ForwardedRef<T>;
  [x: string]: unknown;
}

const Button = <T,>({
  id,
  icon,
  children,
  style,
  ariaLabel,
  iconEnd = false,
  variant = 'text',
  className = '',
  size = 'medium',
  color = 'default',
  shape = 'square',
  hasShadow = false,
  onClick,
  onMouseOver,
  component = 'button',
  ref,
  ...rest
}: Props<T>): JSX.Element => {
  const CONTENT = (
    <>
      {icon && children ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: iconEnd ? 'row-reverse' : 'row',
          }}>
          <div className={!iconEnd ? styles.icon : styles.iconEnd}>{icon}</div>
          {children}
        </div>
      ) : icon && !children ? (
        icon
      ) : (
        children
      )}
    </>
  );

  const CLASSES = `${styles.btn} ${
    hasKey(styles, color) ? styles[color] : ''
  } ${hasKey(styles, size) ? styles[size] : ''} ${
    icon && !children ? styles.btnIcon : ''
  } ${hasKey(styles, variant) ? styles[variant] : ''} ${
    shape === 'round' ? styles.round : ''
  } ${hasShadow ? styles.hasShadow : ''} ${className}`;

  return React.createElement(
    component,
    {
      id: id,
      style: { ...style },
      className: CLASSES.replace(/\s+/g, ' '),
      ref: ref,
      'aria-label': ariaLabel,
      onClick: onClick,
      onMouseOver: onMouseOver,
      ...rest,
    },
    CONTENT
  );
};

export default Button;
