import React from 'react';
import Svg from './svg';

/**
 * @name IconChecked
 * @description SVG icon component
 * @example
 * <IconChecked />
 */
export const IconChecked = (): JSX.Element => (
  <Svg className="checked">
    <title>checked</title>
    <path d="M22.501 10.213l-9.423 9.825-3.564-3.689-1.344 1.391 4.228 4.377c0.189 0.195 0.445 0.352 0.672 0.352s0.476-0.156 0.664-0.344l10.095-10.505-1.329-1.407z" />
  </Svg>
);

/**
 * @name IconClose
 * @description SVG icon component
 * @example
 * <IconClose />
 */
export const IconClose = (): JSX.Element => (
  <Svg className="close">
    <title>close</title>
    <path d="M27.227 3.2l-11.253 11.24-11.2-11.187-1.573 1.573 11.2 11.173-11.2 11.187 1.573 1.56 11.2-11.173 11.253 11.227 1.573-1.56-11.253-11.24 11.253-11.227z" />
  </Svg>
);

/**
 * @name IconMicrosoft
 * @example
 * <IconMicrosoft />
 */
export const IconMicrosoft = (): JSX.Element => (
  <Svg className="ms-logo">
    <title>ms-logo</title>
    <path fill="#f25022" d="M0 15.158h15.158v-15.158h-15.158v15.158z" />
    <path fill="#00a4ef" d="M0 32h15.158v-15.158h-15.158v15.158z" />
    <path fill="#7fba00" d="M16.842 15.158h15.158v-15.158h-15.158v15.158z" />
    <path fill="#ffb900" d="M16.842 32h15.158v-15.158h-15.158v15.158z" />
  </Svg>
);

/**
 * @name IconGoogle
 * @example
 * <IconGoogle />
 */
export const IconGoogle = (): JSX.Element => (
  <Svg className="google-logo">
    <title>google-logo</title>
    <path
      fill="#fbbc05"
      d="M1.614 23.090c-1.034-2.134-1.614-4.54-1.614-7.090s0.58-4.956 1.614-7.090l5.303 4.115c-0.303 0.935-0.467 1.934-0.467 2.974s0.164 2.040 0.467 2.974l-5.303 4.115z"
    />
    <path
      fill="#ea4335"
      d="M1.614 8.911c2.564-5.291 7.921-8.911 14.152-8.911 4.013 0 7.596 1.527 10.391 4l-4.586 4.655c-1.577-1.309-3.583-2.109-5.805-2.109-4.135 0-7.626 2.709-8.849 6.48l-5.303-4.115z"
    />
    <path
      fill="#34a853"
      d="M1.612 23.085l5.3-4.124c1.22 3.778 4.713 6.493 8.853 6.493 4.371 0 7.668-2.255 8.456-6.182h-8.456v-6.182h14.691c0.215 0.945 0.358 1.964 0.358 2.909 0 10.182-7.166 16-15.049 16-6.232 0-11.59-3.621-14.154-8.915z"
    />
    <path
      fill="#4285f4"
      d="M26.042 28.015l-5.034-3.955c1.646-1.054 2.791-2.68 3.214-4.787h-8.456v-6.182h14.691c0.215 0.945 0.358 1.964 0.358 2.909 0 5.215-1.88 9.285-4.773 12.015z"
    />
  </Svg>
);
