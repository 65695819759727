import React from 'react';
import SearchMessages, {
  Props as SearchMessagesProps,
} from './searchMessages/SearchMessages';
import styles from './SearchResults.module.scss';
import SearchResultsTitle from './searchResultsTitle';

interface Props extends SearchMessagesProps {
  length?: number;
  isOpen: boolean;
  noResultsMessage: string | React.ReactNode;
  resultsMessage: string | React.ReactNode;
  onSearchItemClick(e: React.MouseEvent): void;
}

const SearchResults: React.FC<Props> = ({
  isSearchable,
  message,
  isLoading,
  hasError,
  loadingText,
  children,
  length,
  noResultsMessage,
  resultsMessage,
  errorMessage,
  isOpen,
  onSearchMessageClick,
  onSearchItemClick,
  value,
}): JSX.Element => {
  return (
    <div
      className={`${styles.searchResults} ${isOpen ? styles.isOpen : ''} ${
        hasError ? styles.hasError : ''
      }`}>
      <SearchMessages
        errorMessage={errorMessage}
        loadingText={loadingText}
        message={message}
        value={value}
        onSearchMessageClick={onSearchMessageClick}
        hasError={hasError}
        isLoading={isLoading}
        isSearchable={isSearchable}
      />
      {!isLoading &&
        !hasError &&
        (length ? (
          <>
            {value && isSearchable && (
              <SearchResultsTitle>{resultsMessage}</SearchResultsTitle>
            )}
            <span className="searchResultItems" onClick={onSearchItemClick}>
              {children}
            </span>
          </>
        ) : (
          <SearchResultsTitle>
            {noResultsMessage
              ? noResultsMessage
              : `${value} did not match any results`}
          </SearchResultsTitle>
        ))}
    </div>
  );
};

export default SearchResults;
