import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { hasKey } from '../../types/commonTypes';
import Spinner from '../spinner/Spinner';
import styles from './Alert.module.scss';
import AlertTransitions from './AlertTransitions.module.scss';

export interface BaseAlertProps {
  type?: 'success' | 'info' | 'warning' | 'error' | 'loading';
  absolute?: boolean;
  noMargin?: boolean;
  noIcon?: boolean;
  spaceBetween?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
}

interface Props extends BaseAlertProps {
  in?: boolean;
  timeout?: number | { enter?: number | undefined; exit?: number | undefined };
}

export const BaseAlert = ({
  type = 'info',
  absolute = false,
  noMargin = false,
  noIcon = false,
  spaceBetween = false,
  children,
  icon,
}: BaseAlertProps): JSX.Element => {
  return (
    <div
      className={`${styles.alert} ${hasKey(styles, type) ? styles[type] : ''} ${
        absolute ? styles.absolute : ''
      } ${noMargin ? styles.hasNoMargin : ''}`}>
      {!noIcon && icon ? <div className={styles.icon}>{icon}</div> : null}

      {!noIcon && !icon && type === 'success' ? (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M16.852 7.46l-7.2 7.252-2.723-2.724-1.027 1.027 3.23 3.231c.145.144.341.26.514.26s.364-.116.508-.254l7.713-7.754-1.015-1.038z" />
            <path d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 23.002C5.937 23.002.998 18.069.998 12 .998 5.937 5.931.998 12 .998 18.063.998 23.002 5.931 23.002 12c0 6.063-4.939 11.002-11.002 11.002z" />
          </g>
        </svg>
      ) : null}

      {!noIcon && !icon && type === 'info' ? (
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g>
            <g transform="translate(9.846 6.154)">
              <ellipse cx="1.587" cy="1.169" rx="1.154" ry="1.169" />
              <path d="M2.77 11.225V3.742H0v.467h.923v7.016H0v.467h3.692v-.467z" />
            </g>
            <path d="M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 23.002C5.937 23.002.998 18.069.998 12 .998 5.937 5.931.998 12 .998 18.063.998 23.002 5.931 23.002 12c0 6.063-4.939 11.002-11.002 11.002z" />
          </g>
        </svg>
      ) : null}

      {!noIcon && !icon && type === 'warning' ? (
        <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.306 4.994l-11.069 20.2c-0.7 1.262 0.231 2.806 1.694 2.806h22.144c1.456 0 2.387-1.544 1.694-2.806l-11.075-20.2c-0.731-1.325-2.656-1.325-3.387 0zM17.1 13.375l-0.225 7.625h-1.75l-0.225-7.625h2.2zM16 25.15c-0.669 0-1.194-0.506-1.194-1.15s0.525-1.15 1.194-1.15 1.194 0.506 1.194 1.15-0.525 1.15-1.194 1.15z"></path>
        </svg>
      ) : null}

      {!noIcon && !icon && type === 'error' ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm17.2 109.6l-3.1 115.1c-.2 8.2-5.9 14.8-14.1 14.8s-13.9-6.6-14.1-14.8l-3.1-115.1c-.2-9.6 7.5-17.6 17.2-17.6 9.6 0 17.4 7.9 17.2 17.6zM256 354c-10.7 0-19.1-8.1-19.1-18.4s8.4-18.4 19.1-18.4c10.7 0 19.1 8.1 19.1 18.4S266.7 354 256 354z" />
        </svg>
      ) : null}

      {!noIcon && !icon && type === 'loading' ? (
        <div className={styles.spinner}>
          <Spinner size="small" />
        </div>
      ) : null}

      <span
        className={`${styles.content} ${
          spaceBetween ? styles.hasSpaceBetween : ''
        }`}>
        {children}
      </span>
    </div>
  );
};

const Alert = ({
  in: transitionIn = true,
  timeout = 300,
  type = 'info',
  absolute = false,
  noMargin = false,
  noIcon = false,
  spaceBetween = false,
  children,
  icon,
}: Props): JSX.Element => {
  return (
    <CSSTransition
      appear
      unmountOnExit
      in={transitionIn}
      classNames={AlertTransitions}
      timeout={timeout}>
      <BaseAlert
        type={type}
        absolute={absolute}
        noMargin={noMargin}
        noIcon={noIcon}
        spaceBetween={spaceBetween}
        icon={icon}>
        {children}
      </BaseAlert>
    </CSSTransition>
  );
};

export default Alert;
