import React from 'react';
import AvatarContainer, {
  Props as AvatarContainerProps,
} from './avatarContainer/AvatarContainer';
import AvatarContent, {
  Props as AvatarContentProps,
} from './avatarContent/AvatarContent';

interface Props extends AvatarContainerProps, AvatarContentProps {}

export default function Avatar({
  name,
  image,
  onClick,
  isSelected,
  size = 'medium',
  style,
}: Props): JSX.Element {
  return (
    <AvatarContainer onClick={onClick} isSelected={isSelected} size={size}>
      <AvatarContent
        name={name}
        image={image}
        isSelected={isSelected}
        style={style}
      />
    </AvatarContainer>
  );
}
