import React from 'react';
import styles from './AccordionContainer.module.scss';

interface Props {
  isAccordionOpen: boolean;
  hasBorder: boolean;
  className: string;
}

const AccordionContainer: React.FC<Props> = ({
  isAccordionOpen,
  hasBorder,
  className,
  children,
}) => (
  <section
    aria-expanded={isAccordionOpen}
    className={`${styles.accordion} ${className} ${
      hasBorder && !isAccordionOpen ? styles.hasBorder : ''
    }`}>
    {children}
  </section>
);

export default AccordionContainer;
