import React from 'react';
import styles from './LabelRequired.module.scss';

interface Props {
  text: string | React.ReactNode;
}

const LabelRequired = ({ text }: Props): JSX.Element => (
  <>
    {text}
    <span className={styles.required}>*</span>
  </>
);

export default LabelRequired;
