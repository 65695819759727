import React, { forwardRef } from 'react';
import { hasKey } from '../../../types/commonTypes';
import { Size } from '../../../types/interfaces';
import styles from '../input/Input.module.scss';
import InputLabel, { InputLabelProps } from '../input/inputLabel/InputLabel';
import InputMessage, {
  InputMessageProps,
} from '../input/inputMessage/InputMessage';
import InputWrapper, {
  InputWrapperProps,
} from '../input/inputWrapper/InputWrapper';

interface Props
  extends InputWrapperProps,
    InputMessageProps,
    InputLabelProps,
    Size,
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    > {
  name: string;
  touched?: { [key: string]: boolean };
  errors?: { [key: string]: boolean };
  focused?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  wrapperClassName?: string;
  onChange?(e: React.ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(e: React.FocusEvent<HTMLTextAreaElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLElement>): void;
}

const TextArea = (
  {
    label,
    name,
    touched,
    errors,
    errorMsg,
    helpText,
    autoFocus,
    onChange,
    onBlur,
    onKeyDown,
    onKeyUp,
    wrapperClassName,
    fullWidth,
    disabled,
    required,
    size = 'medium',
    className = '',
    ...rest
  }: Props,
  ref: React.Ref<HTMLTextAreaElement>
): JSX.Element => {
  const hasError = touched && errors && !errors[name] && touched[name];
  const hasBlur = touched && touched[name];

  const classes = `${styles.formInput} ${
    hasError && hasBlur ? styles.hasError : ''
  } ${disabled ? styles.isDisabled : ''} ${
    hasKey(styles, size) ? styles[size] : ''
  } ${className}`;

  return (
    <InputWrapper className={wrapperClassName} fullWidth={fullWidth}>
      <InputLabel
        name={name}
        label={label}
        disabled={disabled}
        required={required}
        size={size}
      />
      <div className={styles.formGroupInner}>
        <textarea
          id={name}
          name={name}
          className={classes}
          autoFocus={autoFocus}
          required={required}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          ref={ref}
          {...rest}
        />
      </div>
      <InputMessage
        hasError={hasError}
        hasBlur={hasBlur}
        errorMsg={errorMsg}
        helpText={helpText}
        size={size}
      />
    </InputWrapper>
  );
};

const TextAreaForwardRef = forwardRef(TextArea);
TextAreaForwardRef.displayName = 'TextArea';

export default TextAreaForwardRef;
