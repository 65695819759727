import React from 'react';
import { hasKey } from '../../../types/commonTypes';
import styles from './AvatarContainer.module.scss';

export interface Props {
  /** onClick handler if the avatar is used with selection */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isSelected?: boolean;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
}

const AvatarContainer: React.FC<Props> = ({
  onClick,
  isSelected,
  size = 'medium',
  children,
}): JSX.Element => {
  return (
    <div
      className={`${styles.avatarContainer} ${
        hasKey(styles, size) ? styles[size] : ''
      } ${onClick ? styles.hasAction : ''} ${
        isSelected ? styles.isSelected : ''
      }`}
      onClick={onClick}>
      {children}
    </div>
  );
};

export default AvatarContainer;
