import React from 'react';
import ReactDOM from 'react-dom';
import { hasKey } from '../../types/commonTypes';
import styles from './Portal.module.scss';

interface Props {
  id?: string;
  isBehind?: boolean;
  position?: 'fixed' | 'relative';
}

/**
 * The portal component accepts children elements that needs to live outside of the root
 * e.g. modals, tooltips and contextual menu's.
 */
const Portal: React.FC<Props> = ({
  children,
  id = 'bm-portal',
  position = 'relative',
  isBehind,
}): JSX.Element => {
  let modalRoot = document.getElementById(id);

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'bm-portal');
    document.body.appendChild(modalRoot);
  }

  return ReactDOM.createPortal(
    <div
      className={`${styles.portalContent} ${
        hasKey(styles, position) ? styles[position] : ''
      } ${isBehind ? styles.isBehind : ''}`.replace(/\s+/g, ' ')}>
      {children}
    </div>,
    modalRoot
  );
};

export default Portal;
