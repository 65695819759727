import React, { forwardRef } from 'react';
import { hasKey } from '../../../types/commonTypes';
import { InputType, Size } from '../../../types/interfaces';
import styles from './Input.module.scss';
import InputLabel, { InputLabelProps } from './inputLabel/InputLabel';
import InputMessage, { InputMessageProps } from './inputMessage/InputMessage';
import InputWrapper, { InputWrapperProps } from './inputWrapper/InputWrapper';

interface Props
  extends InputWrapperProps,
    InputMessageProps,
    InputLabelProps,
    InputType,
    Size,
    Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'type' | 'size'
    > {
  name: string;
  value?: string | number | undefined;
  touched?: { [key: string]: boolean };
  errors?: { [key: string]: boolean };
  checked?: boolean;
  focused?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  button?: React.ReactNode;
  wrapperClassName?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLElement>): void;
}

const Input = (
  {
    label,
    checked,
    name,
    touched,
    errors,
    errorMsg,
    helpText,
    value,
    autoFocus,
    onChange,
    onBlur,
    onKeyDown,
    onKeyUp,
    button,
    wrapperClassName,
    fullWidth,
    type = 'text',
    disabled = false,
    required = false,
    size = 'medium',
    className = '',
    ...rest
  }: Props,
  ref: React.Ref<HTMLInputElement>
): JSX.Element => {
  const hasError = touched && errors && !errors[name] && touched[name];
  const hasBlur = touched && touched[name];

  const classes = `${styles.formInput} ${
    hasError && hasBlur ? styles.hasError : ''
  } ${disabled ? styles.isDisabled : ''} ${
    hasKey(styles, size) ? styles[size] : ''
  } ${className}`;

  return (
    <InputWrapper className={wrapperClassName} fullWidth={fullWidth}>
      <InputLabel
        name={name}
        label={label}
        type={type}
        checked={checked}
        disabled={disabled}
        required={required}
        size={size}
        hasError={hasError}
        hasBlur={hasBlur}
      />
      <div className={styles.formGroupInner}>
        <input
          id={name}
          name={name}
          className={classes}
          type={type ? type : 'text'}
          value={value}
          checked={checked}
          autoFocus={autoFocus}
          required={required}
          disabled={disabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          ref={ref}
          {...rest}
        />
        {button}
      </div>
      <InputMessage
        hasError={hasError}
        hasBlur={hasBlur}
        errorMsg={errorMsg}
        helpText={helpText}
        size={size}
      />
    </InputWrapper>
  );
};

const InputForwardRef = forwardRef(Input);
InputForwardRef.displayName = 'Input';

export default InputForwardRef;
