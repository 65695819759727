import React from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside/useOnClickOutside';
import styles from './Search.module.scss';
import SearchInput from './searchInput';
import SearchResults from './searchResults';

interface Props {
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  clearSearch?(): void;
  messageAction?(message: string): void;
  onMultiSelect?(): void;
  /** The name of the search field */
  name: string;
  /** State value (should be name the same as the search field) */
  value: string | Record<string, unknown>;
  message?: string | React.ReactNode;
  loadingText?: string | React.ReactNode;
  errorMessage?: string | React.ReactNode;
  resultsMessage?: string | React.ReactNode;
  noResultsMessage?: string | React.ReactNode;
  label?: string | React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  hasResults?: number;
  isLoading?: boolean;
  hasError?: boolean;
  isMultiSelect?: boolean;
  hasItemsInit?: boolean;
  isSearchable?: boolean;
}

/**
 * The `<Search />` component can be used to wrap any search results list, and can
 * easily be used with the `<SearchItem />` or `<Options />` component.
 */
const Search: React.FC<Props> = ({
  name,
  value,
  clearSearch,
  message = 'Search',
  loadingText = 'Loading results...',
  errorMessage = 'An error occurred please try again later',
  resultsMessage = 'Search results',
  noResultsMessage = 'Search did not match any results',
  label = 'Search and select',
  style = {},
  className = '',
  hasResults = 1,
  isLoading = false,
  hasError = false,
  isMultiSelect = false,
  hasItemsInit = false,
  isSearchable = true,
  children,
  onMultiSelect,
  onChange,
  messageAction,
}) => {
  const [ref, isOpen, setIsOpen] = useOnClickOutside(isMultiSelect);
  const [searchValue, setSearchValue] = React.useState(
    typeof value === 'object' ? Object.values(value)[0] : value
  );

  const inputRef = React.createRef<HTMLInputElement>();

  const handleClick = (): void => {
    if (!isMultiSelect) {
      setIsOpen(false);
    }

    if (isMultiSelect) {
      onMultiSelect && onMultiSelect();
    }
  };

  /**
   * Sets state value if searchable and not multi select
   * and handles if results should be shown depending on if state value has a value,
   * if search items is set initially, and if the results are searchable
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    isSearchable && onChange && onChange(event);

    if (isSearchable && !isMultiSelect) {
      setSearchValue(event.target.value);
    }

    if (hasItemsInit || !isSearchable || event.target.value) {
      setIsOpen(true);
    } else if (!hasItemsInit && !event.target.value) {
      setIsOpen(false);
    }
  };

  /**
   * Sets state isOpen based on if the component is used as a search or select component
   */
  const handleFocus = (): void => {
    if (!isMultiSelect && hasItemsInit && isSearchable) {
      setIsOpen(true);
    }

    if (!hasItemsInit && searchValue === '' && isSearchable) {
      setIsOpen(false);
    }
  };

  /**
   * Toggle results when the component is not searchable
   */
  const toggleSelect = (): void => {
    if (!isSearchable && hasItemsInit) {
      setIsOpen((prev) => !prev);
    }
  };

  /**
   * Handle click on search message
   */
  const handleMessageAction = (): void => {
    messageAction && messageAction(searchValue as string);
  };

  /**
   * Handle key board navigation
   */
  const handleKeyDown = (event: React.KeyboardEvent): void => {
    // Close results if tab navigating
    if (event.key === 'Tab' || event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  /**
   * Focus input field on clearSearch and show results
   * if clearSearch and component has results initially
   */
  const handleClearSearch = (event: React.MouseEvent): void => {
    event.preventDefault();
    const { current } = inputRef;

    if (clearSearch && isSearchable) {
      clearSearch && clearSearch();

      // Open results if hasItemsInit else close on clear
      setSearchValue('');
      setIsOpen(hasItemsInit);

      current && current.focus();
    }
  };

  const searchContainerClass = `${styles.searchContainer} ${
    isOpen ? styles.isOpen : ''
  } ${hasError ? styles.hasError : ''} ${className}`;

  return (
    <div
      style={style}
      ref={ref}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      className={searchContainerClass}>
      <SearchInput
        name={name}
        label={label}
        value={value}
        onChange={handleChange}
        onSearchInputClick={toggleSelect}
        onClearSearchClick={handleClearSearch}
        clearSearch={clearSearch}
        inputRef={inputRef}
        isOpen={isOpen}
        isSearchable={isSearchable}
        className={styles.formInput}
      />
      <SearchResults
        hasError={hasError}
        length={hasResults}
        isLoading={isLoading}
        isOpen={isOpen}
        isSearchable={isSearchable}
        value={searchValue as string}
        errorMessage={errorMessage}
        loadingText={loadingText}
        message={message}
        resultsMessage={resultsMessage}
        noResultsMessage={noResultsMessage}
        onSearchMessageClick={handleMessageAction}
        onSearchItemClick={handleClick}>
        {children}
      </SearchResults>
    </div>
  );
};

export default Search;
