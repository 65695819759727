import React from 'react';
import Spinner from '../../../../spinner/Spinner';
import SearchMessage from '../searchMessage/SearchMessage';

export interface Props {
  isSearchable?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  message: string | React.ReactNode;
  loadingText: string | React.ReactNode;
  errorMessage: string | React.ReactNode;
  value: string;
  onSearchMessageClick(e: React.MouseEvent): void;
}

export default function SearchMessages({
  isSearchable,
  message,
  isLoading,
  hasError,
  loadingText,
  errorMessage,
  onSearchMessageClick,
  value,
}: Props): JSX.Element {
  return (
    <>
      {hasError ? (
        <SearchMessage hasError={hasError} errorMsg={errorMessage} />
      ) : isLoading && loadingText ? (
        <SearchMessage>
          <Spinner size="small" message={loadingText} direction="row" />
        </SearchMessage>
      ) : (
        value &&
        isSearchable &&
        message && (
          <SearchMessage
            text={message}
            value={value}
            onClick={onSearchMessageClick}
          />
        )
      )}
    </>
  );
}
