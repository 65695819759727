import React from 'react';
import { Size } from '../../../../types/interfaces';
import ListItem from '../../../list/item';
import styles from './SearchItem.module.scss';

interface Props extends Size {
  /** Gets called when user clicks the search item  */
  onClick(e: React.MouseEvent): void;
  hasFullWidthBorder?: boolean;
  className?: string;
  isSelected?: boolean;
}

/**
 * Search item wrapper component
 */
const SearchItem: React.FC<Props> = ({
  onClick,
  children,
  isSelected = false,
  hasFullWidthBorder = false,
  className = '',
  size = 'medium',
}): JSX.Element => {
  return (
    <ListItem
      onClick={onClick}
      className={`${styles.searchItem} ${
        isSelected ? styles.isSelected : ''
      } ${className}`}
      hasFullWidthBorder={hasFullWidthBorder}
      size={size}>
      {children}
    </ListItem>
  );
};

export default SearchItem;
