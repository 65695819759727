import React from 'react';
import { Justify } from '../../types/interfaces';
import Modal from '../modal';
import { Typography } from '../typography/Typography';
import styles from './Dialog.module.scss';

interface DialogFormProps {
  id?: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  style?: React.CSSProperties;
  children: React.ReactNode | React.ReactNode[];
}

interface DialogFooterProps {
  isSticky?: boolean;
  style?: React.CSSProperties;
  actions: React.ReactNode;
}

interface DialogHeaderProps {
  isSticky?: boolean;
  className?: string;
  style?: React.CSSProperties;
  title: string | React.ReactNode;
}

interface BaseDialogProps extends Justify {
  /** Used to differentiate multiple dialogs */
  id?: string;
  style?: React.CSSProperties;
  title?: string | React.ReactNode;
  isSticky?: boolean;
  hasSubmitButton?: boolean;
  isForm?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  children?: React.ReactNode | React.ReactNode[];
  content?: React.ReactNode | React.ReactNode[];
  actions?: React.ReactNode | React.ReactNode[];
  maxHeight?: number | string;
}

interface Props extends BaseDialogProps, Justify {
  className?: string;
  width?: number | string;
  maxWidth?: number | string;
  isOpen: boolean;
  /** onClick is invoked on click outside the modal and can be used to for example close the modal  */
  onClick?(): void;
}

/**
 * The dialog component can for example contain text and UI controls.
 * The dialog retain focus until dismissed or a required action has been taken.
 * Use dialogs sparingly because they are interruptive.
 */
const Dialog = ({
  id = 'bm-dialog',
  justify = 'space-between',
  style,
  title,
  isSticky,
  className = '',
  onSubmit,
  children,
  content,
  actions,
  width,
  maxWidth,
  maxHeight,
  onClick,
  isOpen,
}: Props): JSX.Element => (
  <Modal
    onClick={onClick}
    isOpen={isOpen}
    modalId={id}
    translate={[50, 50]}
    className={`${styles.dialog} ${className}`}
    style={{ width, maxWidth }}>
    <BaseDialog
      justify={justify}
      style={style}
      title={title}
      isSticky={isSticky}
      onSubmit={onSubmit}
      content={content}
      actions={actions}
      maxHeight={maxHeight}>
      {children}
    </BaseDialog>
  </Modal>
);

export default Dialog;

export const BaseDialog = ({
  id = 'bm-dialog',
  justify = 'space-between',
  style,
  title,
  isSticky,
  onSubmit,
  children,
  content,
  actions,
  maxHeight,
}: BaseDialogProps): JSX.Element => (
  <DialogForm id={id} onSubmit={onSubmit}>
    {title && <DialogHeader title={title} isSticky={isSticky} />}
    <DialogContent style={{ maxHeight }}>{children || content}</DialogContent>
    {actions && (
      <DialogFooter
        isSticky={isSticky}
        actions={actions}
        style={{
          justifyContent: `${justify}`,
          ...style,
        }}
      />
    )}
  </DialogForm>
);

export const DialogForm = ({
  id,
  onSubmit,
  children,
  style,
}: DialogFormProps): JSX.Element => (
  <form id={id} className="dialogForm" onSubmit={onSubmit} style={{ ...style }}>
    {children}
  </form>
);

export const DialogContent = ({
  children,
  style,
}: {
  children: React.ReactNode | React.ReactNode[];
  style?: React.CSSProperties;
}): JSX.Element => (
  <div className={styles.dialogContent} style={{ ...style }}>
    {children}
  </div>
);

export const DialogFooter = ({
  isSticky,
  style,
  actions,
}: DialogFooterProps): JSX.Element => (
  <footer
    className={`${styles.dialogActions} ${isSticky ? 'sticky' : ''}`}
    style={style}>
    {actions}
  </footer>
);

export const DialogHeader = ({
  isSticky,
  className = '',
  style,
  title,
}: DialogHeaderProps): JSX.Element => (
  <header
    className={`${styles.dialogHeader} ${
      isSticky ? 'sticky' : ''
    } ${className}`}
    style={{ ...style }}>
    <Typography
      variant="h4"
      noMargin
      textAlign="center"
      className="dialogTitle">
      {title}
    </Typography>
  </header>
);
