import React from 'react';
import styles from './AccordionContent.module.scss';

interface Props {
  panelId: string;
  tabId: string;
  isAccordionOpen: boolean;
}

const AccordionContent: React.FC<Props> = ({
  panelId,
  tabId,
  isAccordionOpen,
  children,
}) => (
  <div
    id={panelId}
    aria-labelledby={tabId}
    role="tabpanel"
    aria-hidden={!isAccordionOpen}
    className={`${styles.accordionContent} ${
      isAccordionOpen ? styles.isOpen : ''
    }`}>
    {children}
  </div>
);

export default AccordionContent;
