import { useEffect, useRef, useState } from 'react';

export const useOnClickOutside = (
  initial = false
): [
  React.RefObject<HTMLDivElement>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const [isClickOutside, setClickOutside] = useState(initial);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const handleClickOutside = (event: MouseEvent): void => {
      // If the dom node (event target) is not one of container refs direct children
      if (!ref.current?.contains(event.target as Node)) {
        setClickOutside(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [setClickOutside]);

  return [ref, isClickOutside, setClickOutside];
};
