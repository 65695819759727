import React from 'react';
import { Typography } from '../../../../typography/Typography';
import styles from './SearchMessage.module.scss';

interface Props {
  onClick?(e: React.MouseEvent): void;
  text?: string | React.ReactNode;
  value?: string;
  hasError?: boolean;
  errorMsg?: string | React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Search message - can be used for actionable messages (e.g. add item),
 * and showing loading and error messages
 */
const SearchMessage: React.FC<Props> = ({
  onClick,
  text,
  value,
  children,
  hasError,
  errorMsg,
  className = '',
  style,
}): JSX.Element => {
  let body;

  if (hasError && errorMsg) {
    body = errorMsg;
  } else if (!hasError && text && value) {
    body = `${text} ${value}`;
  } else if (!hasError && text && !value) {
    body = text;
  }

  return (
    <div
      className={`${styles.message} ${className} ${
        hasError ? styles.hasError : ''
      }`}
      style={{ ...style }}
      onClick={onClick}>
      {children ? children : <Typography noMargin>{body}</Typography>}
    </div>
  );
};

export default SearchMessage;
