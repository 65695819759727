import React, { CSSProperties, FC } from 'react';
import { getStyle } from '../../../utils/getStyle';
import styles from './GridItem.module.scss';

export interface GridItemProps {
  xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  xl?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  className?: string;
  style?: CSSProperties;
  order?: number | 'initial';
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'initial';
}

export const GridItem: FC<GridItemProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  style,
  className = '',
  order = 'initial',
  align = 'initial',
}) => {
  let classes = className;

  classes +=
    !xs && !sm && !md && !lg && !xl
      ? `${styles.auto}`
      : `${styles.grid} ${getStyle(styles, `xs${xs}`)} ${getStyle(
          styles,
          `sm${sm}`
        )} ${getStyle(styles, `md${md}`)} ${getStyle(
          styles,
          `lg${lg}`
        )} ${getStyle(styles, `xl${xl}`)}`;

  return (
    <div
      className={classes.trim()}
      style={{ order, alignSelf: align, ...style }}>
      {children}
    </div>
  );
};
