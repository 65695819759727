import React from 'react';
import { Size } from '../../../types/interfaces';
import Input from '../input/Input';
import { InputLabelProps } from '../input/inputLabel/InputLabel';
import { InputMessageProps } from '../input/inputMessage/InputMessage';

interface Props
  extends InputMessageProps,
    InputLabelProps,
    Size,
    Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'type' | 'size'
    > {
  name: string;
  value?: string | number | undefined;
  touched?: { [key: string]: boolean };
  errors?: { [key: string]: boolean };
  checked?: boolean;
  focused?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  className?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLElement>): void;
  ref: React.Ref<HTMLInputElement>;
}

const Checkbox = ({
  label,
  checked,
  name,
  touched,
  errors,
  errorMsg,
  helpText,
  value,
  autoFocus,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  disabled = false,
  required = false,
  size = 'medium',
  className = '',
  ref,
  ...rest
}: Props): JSX.Element => {
  return (
    <Input
      id={name}
      label={label}
      touched={touched}
      errors={errors}
      errorMsg={errorMsg}
      helpText={helpText}
      size={size}
      name={name}
      className={className}
      type="checkbox"
      value={value}
      checked={checked}
      autoFocus={autoFocus}
      required={required}
      disabled={disabled}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      ref={ref}
      {...rest}
    />
  );
};

export default Checkbox;
