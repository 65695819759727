import React from 'react';
import ArrowIcon from '../../icons/animated/arrowIcon/ArrowIcon';
import { Typography } from '../../typography/Typography';
import styles from './AccordionHeader.module.scss';

interface Props {
  title?: string | React.ReactNode;
  isAccordionOpen: boolean;
  tabId: string;
  panelId: string;
  onClick(): void;
}

export default function AccordionHeader({
  title,
  isAccordionOpen,
  tabId,
  panelId,
  onClick,
}: Props): JSX.Element {
  return (
    <header
      id={tabId}
      className={styles.header}
      onClick={onClick}
      aria-controls={panelId}>
      <Typography variant="body2" noMargin isUnselectable>
        {title}
      </Typography>
      <ArrowIcon isOpen={isAccordionOpen} />
    </header>
  );
}
