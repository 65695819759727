import React from 'react';
import { v4 as uuid } from 'uuid';
import AccordionContainer from './accordionContainer/AccordionContainer';
import AccordionContent from './accordionContent/AccordionContent';
import AccordionHeader from './accordionHeader/AccordionHeader';

interface Props {
  /** Sets initial state */
  isOpen?: boolean;
  hasBorder?: boolean;
  title?: string | React.ReactNode;
  tabId?: string;
  className?: string;
}

const Accordion: React.FC<Props> = ({
  isOpen = true,
  hasBorder = true,
  tabId = uuid(),
  className = '',
  title,
  children,
}): JSX.Element => {
  const [isAccordionOpen, setAccordionOpen] = React.useState(isOpen);
  const panelId = uuid();

  return (
    <AccordionContainer
      hasBorder={hasBorder}
      isAccordionOpen={isAccordionOpen}
      className={className}>
      <AccordionHeader
        isAccordionOpen={isAccordionOpen}
        panelId={panelId}
        tabId={tabId}
        title={title}
        onClick={() => setAccordionOpen(!isAccordionOpen)}
      />
      <AccordionContent
        isAccordionOpen={isAccordionOpen}
        panelId={panelId}
        tabId={tabId}>
        {children}
      </AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
