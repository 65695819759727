import React from 'react';
import styles from './ListHeader.module.scss';

interface Props {
  title: string | React.ReactNode;
}

function ListHeader({ title }: Props): JSX.Element {
  return <div className={styles.header}>{title}</div>;
}

export default ListHeader;
