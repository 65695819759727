import React from 'react';
import Button from '../../../button';
import ArrowIcon from '../../../icons/animated/arrowIcon/ArrowIcon';
import { IconClose } from '../../../icons/Icons';
import Input from '../../input/Input';
import styles from './SearchInput.module.scss';

interface Props {
  isOpen: boolean;
  isSearchable?: boolean;
  name: string;
  label: string | React.ReactNode;
  value: string | Record<string, unknown>;
  onSearchInputClick(e: React.MouseEvent): void;
  onClearSearchClick(e: React.MouseEvent): void;
  onChange(e: React.ChangeEvent): void;
  clearSearch?(): void;
  inputRef: React.RefObject<HTMLInputElement>;
  className?: string;
}

const SearchInput = ({
  isOpen,
  isSearchable,
  name,
  label,
  value,
  onSearchInputClick,
  onClearSearchClick,
  clearSearch,
  onChange,
  inputRef,
  className = '',
}: Props): JSX.Element => (
  <div className={styles.searchInput} onClick={onSearchInputClick}>
    <Input
      autoComplete="off"
      name={name}
      label={label}
      value={
        (typeof value === 'object' ? Object.values(value)[0] : value) as string
      }
      readOnly={!isSearchable}
      onChange={onChange}
      ref={inputRef}
      wrapperClassName={styles.searchGroup}
      className={`${styles.formInput} ${
        isSearchable ? 'isSearchable' : styles.isSelectable
      } ${className}`}
      fullWidth
      button={
        clearSearch && isSearchable ? (
          <Button
            icon={<IconClose />}
            onClick={onClearSearchClick}
            className={`${styles.clearSearch} ${!value ? styles.isHidden : ''}`}
          />
        ) : (
          <ArrowIcon isOpen={isOpen} className={styles.arrowIcon} />
        )
      }
    />
  </div>
);

export default SearchInput;
