import React from 'react';
import { Indicator, IndicatorProps } from './indicator/Indicator';
import { Message } from './message/Message';
import { Progress, TransitionProps } from './progress/Progress';
import { SpinnerPortal } from './SpinnerPortal';

interface Props extends IndicatorProps, TransitionProps {
  usePortal?: boolean;
}

/**
 * General purpose loading component.
 * Can be used with and without portal, in fullscreen
 * and integrated in e.g. a button component
 */
const Spinner = ({
  transitionIn = true,
  timeout = 250,
  isFixed = false,
  isFullscreen = false,
  isFullwidth = false,
  message = '',
  direction = 'column',
  theme = 'light',
  size = 'medium',
  usePortal = false,
}: Props): JSX.Element => (
  <SpinnerPortal usePortal={usePortal}>
    <Progress
      transitionIn={transitionIn}
      timeout={timeout}
      direction={direction}>
      <Indicator
        isFixed={isFixed}
        isFullscreen={isFullscreen}
        isFullwidth={isFullwidth}
        message={message}
        theme={theme}
        size={size}
        direction={direction}
      />
      {message ? <Message text={message} size={size} /> : null}
    </Progress>
  </SpinnerPortal>
);

export default Spinner;
