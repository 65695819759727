import React from 'react';
import styles from './InputWrapper.module.scss';

export interface InputWrapperProps {
  className?: string;
  fullWidth?: boolean;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  className = '',
  children,
  fullWidth,
}): JSX.Element => {
  return (
    <div
      className={`${styles.formGroup} ${
        fullWidth ? styles.formGroupFullWidth : ''
      } ${className}`}>
      {children}
    </div>
  );
};

export default InputWrapper;
