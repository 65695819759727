import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { hasKey } from '../../../types/commonTypes';
import styles from './Progress.module.scss';

export interface TransitionProps {
  transitionIn?: boolean;
  timeout?: number | { enter?: number | undefined; exit?: number | undefined };
}

interface ProgressProps extends TransitionProps {
  direction: 'column' | 'row';
}

export const Progress: React.FC<ProgressProps> = ({
  transitionIn = true,
  timeout = 250,
  direction,
  children,
}): JSX.Element => (
  <CSSTransition appear in={transitionIn} classNames={styles} timeout={timeout}>
    <div
      className={`${styles.progress} ${
        hasKey(styles, direction) ? styles[direction] : ''
      }`}>
      {children}
    </div>
  </CSSTransition>
);
