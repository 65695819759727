import React from 'react';
import { hasKey } from '../../../types/commonTypes';
import { Size } from '../../../types/interfaces';
import styles from './Indicator.module.scss';
export interface IndicatorProps extends Size {
  theme?: 'light' | 'dark';
  message?: string | React.ReactNode;
  direction?: 'column' | 'row';
  isFixed?: boolean;
  /** Display spinner in full-screen */
  isFullscreen?: boolean;
  /** Center spinner */
  isFullwidth?: boolean;
}

export const Indicator = ({
  isFixed = false,
  isFullscreen = false,
  isFullwidth = false,
  message = '',
  theme = 'light',
  size = 'medium',
  direction = 'column',
}: IndicatorProps): JSX.Element => (
  <div
    className={`${styles.loading} ${isFixed ? styles.isFixed : ''} ${
      isFullscreen ? styles.isFullscreen : ''
    } ${isFullwidth ? styles.isFullwidth : ''} ${
      hasKey(styles, theme) ? styles[theme] : ''
    } ${hasKey(styles, size) ? styles[size] : ''}`}
    style={{
      marginRight: message && direction === 'row' ? '1rem' : 0,
      marginBottom: message && direction === 'column' ? '1rem' : 0,
    }}
  />
);
