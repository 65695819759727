import React from 'react';
import styles from './ArrowIcon.module.scss';

interface Props {
  isOpen: boolean;
  className?: string;
}

function ArrowIcon({ isOpen, className = '' }: Props): JSX.Element {
  return (
    <span
      className={`${styles.arrowIcon} ${
        isOpen ? styles.isOpen : ''
      } ${className}`}
    />
  );
}

export default ArrowIcon;
