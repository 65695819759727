import React from 'react';
import { hasKey } from '../../../../types/commonTypes';
import { InputType, Size } from '../../../../types/interfaces';
import { Typography } from '../../../typography/Typography';
import LabelRequired from '../../label/required/LabelRequired';
import styles from './InputLabel.module.scss';

export interface InputLabelProps {
  label?: string | React.ReactNode;
  disabled?: boolean;
}

interface Props extends InputLabelProps, Size, InputType {
  name: string;
  checked?: boolean;
  required?: boolean;
  hasError?: boolean | undefined;
  hasBlur?: boolean | undefined;
}

export default function InputLabel({
  name,
  label = '',
  type,
  checked = false,
  disabled = false,
  required = false,
  size = 'medium',
  hasError = false,
  hasBlur = false,
}: Props): JSX.Element {
  const classes = `${styles.formLabel} ${
    type === 'checkbox' ? styles.inputCheckbox : ''
  } ${checked ? styles.isChecked : ''} ${disabled ? styles.isDisabled : ''}
  ${hasKey(styles, size) ? styles[size] : ''} ${
    hasError && hasBlur ? styles.hasError : ''
  }`;

  const isInvisible = label || type === 'checkbox';

  return (
    isInvisible && (
      <label className={classes} htmlFor={name}>
        {required ? (
          <LabelRequired text={label} />
        ) : (
          label || <Typography variant="srOnly">{name}</Typography>
        )}
      </label>
    )
  );
}
