import React from 'react';
import { Typography } from '../../../../typography/Typography';
import styles from './SearchResultsTitle.module.scss';

const SearchResultsTitle: React.FC = ({ children }): JSX.Element => (
  <Typography noMargin isUnselectable className={styles.title}>
    {children}
  </Typography>
);

export default SearchResultsTitle;
