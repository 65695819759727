import React from 'react';
import styles from './Svg.module.scss';

interface Props {
  className: string;
  children: React.ReactNode | React.ReactNode[];
}

/**
 * @name Svg
 * @description wrapper component
 * @example
 * <Svg className="close"><path="..." /></Svg>
 */
const Svg = ({ className, children }: Props): JSX.Element => {
  return (
    <svg
      id={`${className && 'icon-' + className}`}
      className={`${styles.icon} ${className && 'icon-' + className}`}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg">
      {children}
    </svg>
  );
};

export default Svg;
