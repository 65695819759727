import React from 'react';
import { hasKey } from '../../types/commonTypes';
import styles from './Image.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  src: string;
  alt?: string;
  variant?: 'imgFluid' | 'imgCircle' | 'imgRounded';
  className?: string;
  style?: React.CSSProperties;
}

export const Image: React.FC<Props> = ({
  src,
  alt,
  variant = '',
  className = '',
  style,
  ...rest
}) => {
  return (
    <img
      src={src}
      alt={alt}
      className={`${styles.img} ${
        hasKey(styles, variant) ? styles[variant] : null
      } ${className}`}
      style={{ ...style }}
      {...rest}
    />
  );
};
