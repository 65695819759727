import { useEffect, useState } from 'react';

export const useDebounce = (value: string, delay = 500): string => {
  const [debounceValue, setDebounceValue] = useState<string>();

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => {
      clearTimeout(debounce);
    };
  }, [delay, value]);

  return debounceValue || '';
};
