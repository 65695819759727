import React from 'react';
import { hasKey } from '../../../types/commonTypes';
import { Size } from '../../../types/interfaces';
import styles from './Message.module.scss';

interface Props extends Size {
  text: string | React.ReactNode;
}

export const Message = ({ text, size = 'medium' }: Props): JSX.Element | null =>
  text ? (
    <span
      className={`${styles.message} ${
        hasKey(styles, size) ? styles[size] : ''
      }`}>
      {text}
    </span>
  ) : null;
