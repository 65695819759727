import React from 'react';
import { IconChecked } from '../../icons/Icons';
import { Image } from '../../image/Image';
import { Typography } from '../../typography/Typography';
import initialsToColorCode from '../avatarColor/AvatarColor';
import getInitials from '../utils/getInitials';
import splitName from '../utils/splitName';
import styles from './AvatarContent.module.scss';

export interface Props {
  /** first and last word of the string value is extracted to initials */
  name: string;
  /** avatar image src. Can be either an URL or an image file */
  image?: string | React.ReactNode;
  style?: React.CSSProperties;
  isSelected?: boolean;
}

export default function AvatarContent({
  name,
  image,
  isSelected,
  style,
}: Props): JSX.Element {
  const names = splitName(name);
  const initials = getInitials(names);
  const backgroundColor = initialsToColorCode(initials);

  return (
    <div
      className={`${styles.avatar} ${styles.avatarBackground}`}
      style={{ backgroundColor, ...style }}>
      {isSelected && <IconChecked />}
      {!image ? (
        <Typography isUnselectable noMargin noLineHeight color="light">
          {initials}
        </Typography>
      ) : typeof image === 'string' ? (
        <Image src={image} alt={name} className={styles.image} />
      ) : (
        image
      )}
    </div>
  );
}
