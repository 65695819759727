import React from 'react';
import { hasKey } from '../../../../types/commonTypes';
import { Size } from '../../../../types/interfaces';
import styles from './InputMessage.module.scss';

export interface InputMessageProps {
  errorMsg?: string | React.ReactNode;
  helpText?: string | React.ReactNode;
}

interface Props extends InputMessageProps, Size {
  hasError: boolean | undefined;
  hasBlur: boolean | undefined;
}

export default function InputMessage({
  hasError,
  hasBlur,
  errorMsg,
  helpText,
  size = 'medium',
}: Props): JSX.Element | null {
  const messageClassName = `${styles.formText} ${
    hasKey(styles, size) ? styles[size] : ''
  }`;

  return hasError && hasBlur ? (
    <span className={`${messageClassName} ${styles.hasError}`}>{errorMsg}</span>
  ) : helpText ? (
    <span className={messageClassName}>{helpText}</span>
  ) : null;
}
